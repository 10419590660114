import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Nasze usługi | Usługi RIO Club
			</title>
			<meta name={"description"} content={"Uwolnij swój potencjał z każdym ruchem w RIO Club"} />
			<meta property={"og:title"} content={"Nasze usługi | Usługi RIO Club"} />
			<meta property={"og:description"} content={"Uwolnij swój potencjał z każdym ruchem w RIO Club"} />
			<meta property={"og:image"} content={"https://froviada.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://froviada.com/img/5606891.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://froviada.com/img/5606891.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://froviada.com/img/5606891.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://froviada.com/img/5606891.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://froviada.com/img/5606891.png"} />
			<meta name={"msapplication-TileImage"} content={"https://froviada.com/img/5606891.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Nasze usługi
				</Text>
				<Text font="--lead" color="--darkL2" padding="0px 0px 50px 0px">
					RIO Club to coś więcej niż miejsce do podnoszenia ciężarów - to miejsce, w którym każde wyzwanie jest okazją do rozwoju. Odkryj wachlarz usług zaprojektowanych, aby napędzać Twoją ewolucję fitness.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Nasze spektrum usług
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							- Siła i kondycja: Nasze kompleksowe strefy podnoszenia ciężarów i treningu oporowego stanowią podstawę sprawności fizycznej. Nasz wybór sprzętu honoruje klasykę, jednocześnie uwzględniając potrzeby współczesnego sportowca.
							<br /><br />
							- Cardiovascular Suite: Od bieżni z widokiem po stacje rowerowe, które przekraczają Twoje granice, nasza strefa cardio to bicie serca aktywności zaprojektowanej w celu poprawy wytrzymałości i dodania energii na cały dzień.
							<br /><br />
							- Zajęcia grupowe: Zanurz się w synergii wspólnego wysiłku dzięki naszemu zróżnicowanemu harmonogramowi zajęć. Joga, pilates, spinning i nie tylko - każde zajęcia to okazja do nawiązania kontaktu i rozwoju w naszej społeczności.
							<br /><br />
							- Trening personalny: Nasi trenerzy są przewodnikami w podróży fitness. Oferują spersonalizowane schematy, motywację i wiedzę, aby pomóc Ci osiągnąć nowe wyżyny osobistych osiągnięć.
							<br /><br />
							- Warsztaty odnowy biologicznej: Holistyczne zdrowie jest naszą mantrą, a nasze warsztaty obejmują odżywianie, dobre samopoczucie psychiczne i techniki poprawiające styl życia poza siłownią.

						</Text>
					</Box>
				</Box>
				<Box
					width="40%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://froviada.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						width="100%"
						object-fit="none"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="45%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Połącz się i odkrywaj
				</Text>
			</Box>
			<Box
				width="55%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Ciekawość jest pierwszym krokiem do transformacji. Aby uzyskać informacje na temat pełnego zakresu naszych usług i dostosować swój plan fitness:
					<br /><br/>
					- Engage: Wpadnij na osobistą wycieczkę i zobacz puls RIO Club.
					<br />
					- Zapytaj: Skontaktuj się z nami, aby poznać szczegóły, które zaspokoją Twoją ciekawość i ambicje.
					<br />
					- Doświadczenie: Odwiedź nas i poczuj energię, która sprawia, że RIO Club jest latarnią zdrowia i witalności.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});